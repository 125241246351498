import styled from "@emotion/styled";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import AspectRatio from "../components/AspectRatio";
import EImage from "../components/editable/EImage";
import EMarkdown from "../components/editable/EMarkdown";
import PageContainer from "../components/PageContainer";

const HeaderTitleDesktop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 5%;
`;

const Home: React.FC = (props) => {
  //TODO: select image
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AspectRatio ratio={matches ? 0.5 : 1}>
        <EImage
          id="homeVisual"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </AspectRatio>
      <HeaderTitleDesktop>
        <PageContainer style={{ display: "flex" }}>
          <div>
            <Typography variant="h1">WILLKOMMEN!</Typography>

            <Button
              variant="outlined"
              color="primary"
              size="large"
              fullWidth
              onClick={() => navigate("/werke")}
            >
              Zu den Werken
            </Button>
          </div>
        </PageContainer>
      </HeaderTitleDesktop>

      <PageContainer>
        <EMarkdown id="test" />
      </PageContainer>
    </div>
  );
};

export default Home;
