import { doc, getFirestore, updateDoc } from "firebase/firestore";
import React from "react";
import { useDocument } from "./subscriptions";

type Data = { [index: string]: any };

export const DataContext = React.createContext<Data | null | undefined>({});

export const DataProvider: React.FC = ({ children }) => {
  const data = useDocument<Data>("website/data");
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export const save = async (values: { [index: string]: any }, path?: string) => {
  try {
    await updateDoc(doc(getFirestore(), path ?? "website/data"), values);
  } catch (e) {
    console.log(e);
  }
};
