import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  createTheme,
  CssBaseline,
  PaletteOptions,
  ThemeProvider,
} from "@mui/material";
import ScrollToTop from "./components/ScrollToTop";
import { initializeApp } from "firebase/app";
import { DataProvider } from "./DataContext";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import "lato-font";
import "poppins-font";
import { AuthProvider } from "./AuthContext";
import Login from "./login/Login";

//firebase
const firebaseConfig = {
  apiKey: "AIzaSyCrP3SXNc9HhM3Zhnnn9cNZROhk-QmDJf4",
  authDomain: "dorita-1375f.firebaseapp.com",
  databaseURL: "https://dorita-1375f.firebaseio.com",
  projectId: "dorita-1375f",
  storageBucket: "dorita-1375f.appspot.com",
  messagingSenderId: "732511482013",
  appId: "1:732511482013:web:9f97a4ed7c054d7a",
};
initializeApp(firebaseConfig);

const palette: PaletteOptions = {
  primary: {
    main: "#d62263",
    contrastText: "#fff",
    /*main: '#AD974F',
      dark: '#8E793E',
      contrastText: '#FFF'*/
  },
  secondary: {
    main: "#231F20",
    contrastText: "#FFF",
  },
};

const typography: TypographyOptions = {
  fontFamily: "Lato", //'TypoPRO Bebas Neue'
  h1: {
    fontSize: 40,
    fontWeight: 400,
  },
  h2: {
    fontSize: 34,
    fontWeight: 400,
  },
};

const theme = createTheme({
  typography,
  palette,
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <DataProvider>
          <BrowserRouter>
            <CssBaseline />
            <ScrollToTop />
            <Routes>
              <Route path="/admin" element={<Login />} />
              <Route path="*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </DataProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
