import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";

const FTextField: React.FC<TextFieldProps> = (props) => {
  const [field, meta] = useField(props.name!);

  return (
    <TextField
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      variant="outlined"
      fullWidth
    />
  );
};

export default FTextField;
