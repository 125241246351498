import { Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { ReactNode } from "react";
import AuthContext from "../../AuthContext";
import { DataContext, save } from "../../DataContext";
import FTextField from "../FTextfield";

//TODO: render list

interface Props {
  id: string;
  render: (value: string, props: any) => ReactNode;
}

const EList: React.FC<Props> = (props) => {
  const data = React.useContext(DataContext);
  const value = (data && (data![props.id] as string[])) || [];
  const [editing, setEditing] = React.useState(false);
  const user = React.useContext(AuthContext);

  //style={user ? { ...{ cursor: "pointer" } } : undefined}
  //       onClick={user ? () => setEditing(true) : undefined}
  const childProps = user
    ? {
        style: { cursor: "pointer" },
        onClick: () => setEditing(true),
      }
    : {};

  return (
    <>
      {value.map((v) => props.render(v, childProps))}
      {user && !value?.length && props.render("Click me", childProps)}

      {data && editing && (
        <EditDialog
          id={props.id}
          value={value}
          open={editing}
          setOpen={setEditing}
        />
      )}
    </>
  );
};

export default EList;

interface DialogProps {
  id: string;
  value: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditDialog: React.FunctionComponent<DialogProps> = ({
  open,
  setOpen,
  id,
  value,
}) => {
  const initialValues: any = {
    [id]: value || [],
  };

  const onSubmit = (values: any) => {
    save(values);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ width: 500 }}>
            <Grid container spacing={2}>
              <FieldArray name={id}>
                {(fieldArrayProps) => {
                  const { push, remove, form } = fieldArrayProps;
                  const add = () => push("");
                  const values: string[] = form.values[id];

                  return (
                    <>
                      {values.map((_, index) => (
                        <Grid
                          item
                          xs={12}
                          key={index}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <FTextField name={`${id}[${index}]`} />
                          <IconButton
                            onClick={() => remove(index)}
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Button variant="outlined" size="small" onClick={add}>
                          Hinzufügen
                        </Button>
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpen(false)}>
              Abbrechen
            </Button>
            <Button color="primary" type="submit">
              Speichern
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormikProvider>
  );
};
