import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import * as React from "react";
import AuthContext from "../../AuthContext";
import { DataContext, save } from "../../DataContext";
import ImageField from "../ImageField";

interface Props {
  id: string;
}

const EImage: React.FC<
  Props &
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >
> = (props) => {
  const data = React.useContext(DataContext);
  const src = (data && (data![props.id] as string)) || "";
  const [editing, setEditing] = React.useState(false);
  const user = React.useContext(AuthContext);

  return (
    <>
      <img
        alt="img"
        src={src}
        {...props}
        style={
          user
            ? { ...{ cursor: "pointer" }, ...props.style }
            : { ...props.style }
        }
        onClick={user ? () => setEditing(true) : undefined}
      />
      {data && editing && (
        <SiteImageDialog
          id={props.id}
          value={src}
          open={editing}
          setOpen={setEditing}
        />
      )}
    </>
  );
};

export default EImage;

interface DialogProps {
  id: string;
  value: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const SiteImageDialog: React.FunctionComponent<DialogProps> = ({
  open,
  setOpen,
  id,
  value,
}) => {
  const initialValues: any = {
    [id]: value || null,
  };

  const onSubmit = (values: any) => {
    save(values);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ width: 500 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ImageField id={id} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpen(false)}>
              Abbrechen
            </Button>
            <Button color="primary" type="submit">
              Speichern
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormikProvider>
  );
};
