import { Container, ContainerTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import * as React from "react";

const PageContainer: React.FC<DefaultComponentProps<ContainerTypeMap>> = (
  props
) => {
  return <Container maxWidth="md" sx={{ padding: 4 }} {...props}></Container>;
};

export default PageContainer;
