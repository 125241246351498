import { useContext, useEffect, useState } from "react";
import { collection, doc, getFirestore, onSnapshot } from "firebase/firestore";

export const useDocument = <T>(path: string): T | null | undefined => {
  const [data, setData] = useState<T | null | undefined>();

  useEffect(() => {
    const db = getFirestore();
    return onSnapshot(doc(db, path), (snapshot) => {
      if (!snapshot.exists()) {
        setData(null);
        return;
      }

      const data = snapshot.data() as T;
      setData(data);
    });
  }, []);

  return data;
};
