import { Box, styled } from "@mui/material";
import * as React from "react";
import EMarkdown from "../components/editable/EMarkdown";
import PageContainer from "../components/PageContainer";

const Header = styled(Box)(({ theme }) => ({
  color: "#fff",
  width: "100%",
  height: 200,
  backgroundColor: theme.palette.primary.main,
}));

const Blog: React.FC = (props) => {
  return (
    <>
      <Header>
        <PageContainer>
          <EMarkdown id="blogTitle" />
        </PageContainer>
      </Header>
      <PageContainer>
        <EMarkdown id="blogContent" />
      </PageContainer>
    </>
  );
};

export default Blog;
