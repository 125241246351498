import { Delete, DragHandle } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { ReactNode } from "react";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import AuthContext from "../../AuthContext";
import { DataContext, save } from "../../DataContext";
import { arrayMove } from "../../util";
import FTextField from "../FTextfield";
import ImageField from "../ImageField";

//TODO: sort list

interface Painting {
  category: string;
  description: string;
  title: string;
  image: string;
}

interface Props {
  id: string;
  render: (value: Painting, props: any) => ReactNode;
  filter: (value: Painting) => boolean;
}

const EPaintingList: React.FC<Props> = (props) => {
  const data = React.useContext(DataContext);
  const value = (data && (data![props.id] as Painting[])) || [];
  const [editing, setEditing] = React.useState(false);
  const user = React.useContext(AuthContext);

  //style={user ? { ...{ cursor: "pointer" } } : undefined}
  //       onClick={user ? () => setEditing(true) : undefined}
  const childProps = user
    ? {
        style: { cursor: "pointer" },
        onClick: () => setEditing(true),
      }
    : {};

  return (
    <>
      {value.filter(props.filter).map((v) => props.render(v, childProps))}
      {user &&
        !value?.length &&
        props.render(
          { title: "Click me", description: "", category: "", image: "" },
          childProps
        )}

      {data && editing && (
        <EditDialog
          id={props.id}
          value={value}
          open={editing}
          setOpen={setEditing}
        />
      )}
    </>
  );
};

export default EPaintingList;

interface DialogProps {
  id: string;
  value: Painting[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditDialog: React.FunctionComponent<DialogProps> = ({
  open,
  setOpen,
  id,
  value,
}) => {
  const initialValues: any = {
    [id]: value || [],
  };

  const onSubmit = (values: any) => {
    save(values);
    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{}}>
            <FieldArray name={id}>
              {(fieldArrayProps) => {
                const { push, remove, form } = fieldArrayProps;
                const add = () =>
                  push({ title: "", category: "", description: "" });
                const values: string[] = form.values[id];

                const onSortEnd = (oldIndex: number, newIndex: number) => {
                  arrayMove(values, oldIndex, newIndex);
                  form.setFieldValue(id, values);
                };

                return (
                  <>
                    <SortableList onSortEnd={onSortEnd}>
                      <Grid container spacing={4}>
                        {values.map((_, index) => (
                          <SortableItem key={index}>
                            <Grid
                              item
                              container
                              spacing={1}
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                zIndex: 100000,
                              }}
                            >
                              <Grid item xs={3}>
                                <ImageField id={`${id}[${index}].image`} />
                              </Grid>
                              <Grid item container spacing={1} xs={9}>
                                <Grid item xs={6}>
                                  <FTextField
                                    label="Titel"
                                    name={`${id}[${index}].title`}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <FTextField
                                    label="Kategorie"
                                    name={`${id}[${index}].category`}
                                  />
                                </Grid>
                                <Grid item xs={2} style={{ margin: "auto" }}>
                                  <SortableKnob>
                                    <DragHandle />
                                  </SortableKnob>
                                </Grid>
                                <Grid item xs={10}>
                                  <FTextField
                                    label="Beschreibung"
                                    name={`${id}[${index}].description`}
                                  />
                                </Grid>
                                <Grid item xs={2} style={{ margin: "auto" }}>
                                  <IconButton
                                    onClick={() => remove(index)}
                                    size="small"
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Grid>
                          </SortableItem>
                        ))}
                        <Grid item xs={12}>
                          <Button variant="outlined" size="small" onClick={add}>
                            Hinzufügen
                          </Button>
                        </Grid>
                      </Grid>
                    </SortableList>
                  </>
                );
              }}
            </FieldArray>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setOpen(false)}>
              Abbrechen
            </Button>
            <Button color="primary" type="submit">
              Speichern
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </FormikProvider>
  );
};
