import { Mail, Phone } from "@mui/icons-material";
import { Box, styled } from "@mui/material";
import * as React from "react";
import EMarkdown from "../components/editable/EMarkdown";
import PageContainer from "../components/PageContainer";

const Header = styled(Box)(({ theme }) => ({
  color: "#fff",
  width: "100%",
  height: 200,
  backgroundColor: theme.palette.primary.main,
}));

const Kontakt: React.FC = (props) => {
  return (
    <div>
      <Header>
        <PageContainer>
          <EMarkdown id="contactTitle" />
        </PageContainer>
      </Header>
      <PageContainer>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Phone style={{ marginRight: 16, marginBottom: 8 }} color="primary" />
          <EMarkdown id="contactPhone" />
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Mail style={{ marginRight: 16, marginBottom: 8 }} color="primary" />
          <EMarkdown id="contactEmail" />
        </Box>
        <EMarkdown id="contactContent" />
      </PageContainer>
    </div>
  );
};

export default Kontakt;
