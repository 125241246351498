import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { useField, useFormikContext } from "formik";
import { nanoid } from "nanoid";
import * as React from "react";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  fileToBase64,
  imageProcessor,
  resize,
  sharpen,
} from "ts-image-processor";

const Zone = styled.div`
  min-height: 56px;
  width: 100%;
  border-radius: 8px;
  border: 1px dashed ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Image = styled.img`
  object-fit: cover;
  min-height: 40px;
  width: 100%;
  border-radius: 8px;
`;

interface Props {
  id: string;
}

function base64ToBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

const ImageField: React.FunctionComponent<Props> = ({ id }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(id);
  const [loading, setLoading] = useState(false);

  const fileId = nanoid(11);
  async function onDropAccepted(files: File[]) {
    let file = files[0];

    const original = await fileToBase64(file);
    const processed = await imageProcessor
      .src(original, { jpgQuality: 0.9 })
      .pipe(resize({ maxWidth: 1920, maxHeight: 1080 }), sharpen());

    setLoading(true);

    const r = ref(getStorage(), `${fileId}.jpg`);
    try {
      await uploadBytesResumable(r, base64ToBlob(processed));
      const url = await getDownloadURL(r);
      setFieldValue(id as never, url);
    } finally {
      setLoading(false);
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg",
    onDropAccepted: onDropAccepted,
  });

  return (
    <Zone {...getRootProps()} color={"grey"}>
      <input {...getInputProps()} />
      {field.value && field.value && !loading && (
        <Image alt="Image" src={field.value} />
      )}
      {loading && <CircularProgress />}
    </Zone>
  );
};

export default ImageField;
