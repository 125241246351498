import { ArrowUpward } from "@mui/icons-material";
import {
  Box,
  Divider,
  Fab,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import EList from "../components/editable/EList";
import EMarkdown from "../components/editable/EMarkdown";
import EPaintingList from "../components/editable/PaintingList";
import PageContainer from "../components/PageContainer";

const Werke: React.FC = (props) => {
  const [category, setCategory] = React.useState<string | null>(null);
  const theme = useTheme();
  const ref = React.useRef(null);

  const selected = {
    border: `solid 2px ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  };

  return (
    <div
      style={{
        display: "flex",
        height: "calc(100% - 48px)",
        position: "relative",
      }}
    >
      <div style={{ overflow: "auto", flex: 1 }}>
        <Divider ref={ref} />

        <PageContainer>
          <EMarkdown id="werkeTitle" />

          <Box m={2} />
          <Grid container spacing={4}>
            <EList
              id="werkeKategorien"
              render={(c, props) => (
                <Grid key={c} item xs={6} md={4} lg={2} {...props}>
                  <Paper
                    variant="outlined"
                    onClick={() => setCategory(category === c ? null : c)}
                    style={c === category ? selected : undefined}
                    sx={{ cursor: "pointer", padding: 2, textAlign: "center" }}
                  >
                    {c}
                  </Paper>
                </Grid>
              )}
            ></EList>
          </Grid>
          <Box m={6} />
          <Divider />
          <Box m={6} />
          <Grid container spacing={10}>
            <EPaintingList
              id="werke"
              filter={(w) => !category || w.category === category}
              render={(w, props) => (
                <Grid key={w.image} item xs={12} {...props}>
                  <div>
                    <img
                      alt=""
                      style={{
                        maxHeight: 700,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: 5,
                      }}
                      src={w.image}
                    />
                  </div>

                  <Typography
                    variant="h4"
                    sx={{ marginTop: 2, textAlign: "center" }}
                  >
                    <b>{w.title}</b>
                  </Typography>
                  <Typography sx={{ textAlign: "center" }} variant="h6">
                    {w.description}
                  </Typography>
                </Grid>
              )}
            />
          </Grid>
        </PageContainer>
      </div>

      <Fab
        style={{ position: "absolute", bottom: 16, right: 16 }}
        onClick={() =>
          (ref.current as any).scrollIntoView({ behavior: "smooth" })
        }
      >
        <ArrowUpward />
      </Fab>
    </div>
  );
};

export default Werke;
