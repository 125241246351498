import { Box, Button, Grid, Typography } from "@mui/material";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { FormikProvider, useFormik } from "formik";
import * as React from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../AuthContext";
import FTextField from "../components/FTextfield";

interface LoginData {
  email: string;
  password: string;
}

const Login: React.FC = (props) => {
  const onSubmit = (values: LoginData) => {
    signInWithEmailAndPassword(getAuth(), values.email, values.password);
  };

  const formik = useFormik<LoginData>({
    initialValues: { email: "", password: "" },
    onSubmit,
  });

  const user = React.useContext(AuthContext);

  if (user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Box sx={{ padding: 4, maxWidth: 400, margin: "auto" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Admin-Login</Typography>
              </Grid>
              <Grid item xs={12}>
                <FTextField name="email" label="Email" type="email" />
              </Grid>
              <Grid item xs={12}>
                <FTextField name="password" label="Email" type="password" />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" type="submit" fullWidth>
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </FormikProvider>
    </>
  );
};

export default Login;
