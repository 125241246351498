import React, { useEffect, useState } from "react";
import { getAuth, onIdTokenChanged, User } from "firebase/auth";

export const AuthContext = React.createContext<User | null | undefined>(null);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onIdTokenChanged(auth, (user) => {
      setUser(user);
    });

    return unsubscribe;
  });

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export default AuthContext;
export { AuthProvider };
