interface Props {
  ratio: number;
}

const AspectRatio: React.FC<Props> = ({ ratio, children }) => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: `${ratio * 100}%`,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AspectRatio;
