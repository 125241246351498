import { Link, Typography } from "@mui/material";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

interface IMarkdownProps {}

const Markdown: React.FunctionComponent<IMarkdownProps> = (props) => {
  const navigate = useNavigate();
  //TODO
  return (
    <ReactMarkdown
      children={props.children! as string}
      components={{
        a: (props) => (
          <Link
            underline="always"
            color="primary"
            href={props.href}
            onClick={(e) => {
              if (props.href?.startsWith("/")) {
                navigate(props.href);
                e.preventDefault();
              }
            }}
          >
            {props.children}
          </Link>
        ),
        img: (props) => <img {...props} style={{ maxWidth: "100%" }} />,
        h1: (props) => <Typography variant="h3">{props.children}</Typography>,
        h2: (props) => <Typography variant="h4">{props.children}</Typography>,
        h3: (props) => <Typography variant="h5">{props.children}</Typography>,
        p: (props) => (
          <Typography
            variant="body1"
            style={{
              whiteSpace: "pre-line",
              marginBottom: 16,
              marginTop: 8,
            }}
          >
            {props.children}
          </Typography>
        ),
      }}
    />
  );
};

export default Markdown;
