import { useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import AspectRatio from "../components/AspectRatio";
import EImage from "../components/editable/EImage";
import EMarkdown from "../components/editable/EMarkdown";
import PageContainer from "../components/PageContainer";

const About: React.FC = (props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div>
      <AspectRatio ratio={matches ? 0.4 : 1}>
        <EImage
          id="aboutVisual"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </AspectRatio>
      <PageContainer>
        <EMarkdown id="about" />
      </PageContainer>
    </div>
  );
};

export default About;
