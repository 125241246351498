import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { nanoid } from "nanoid";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { save } from "../DataContext";

interface IAppProps {}

const Newsletter: React.FunctionComponent<IAppProps> = (props) => {
  console.log("asdf");
  const [email, setEmail] = React.useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const open = !!searchParams.get("newsletter");

  const subscribe = () => {
    const id = nanoid(11);
    try {
      addDoc(collection(getFirestore(), "newsletter"), {
        email,
        createdAt: Date(),
      });
    } catch (e) {
      console.log(e);
    }

    setSearchParams("");
  };

  return (
    <Dialog open={open}>
      <DialogContent style={{ textAlign: "center" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">Abonniere meinen Newsletter</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Gib deine Email ein"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={subscribe}
            >
              abonnieren
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="text"
              size="large"
              fullWidth
              onClick={() => setSearchParams("")}
            >
              Nein Danke
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Newsletter;
