import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@mui/material";
import * as React from "react";
import { AuthContext } from "../../AuthContext";
import { DataContext, save } from "../../DataContext";
import Markdown from "../Markdown";

interface Props {
  id: string;
}

const EMarkdown: React.FC<Props> = (props) => {
  const data = React.useContext(DataContext);
  const value = (data && (data![props.id] as string)) || "";
  const [editing, setEditing] = React.useState(false);
  const user = React.useContext(AuthContext);

  return (
    <>
      <div
        className="container"
        style={user ? { ...{ cursor: "pointer" } } : undefined}
        onClick={user ? () => setEditing(true) : undefined}
      >
        {<Markdown>{value || (user && "Click me!")}</Markdown>}
      </div>
      {data && editing && (
        <EditDialog
          id={props.id}
          value={value}
          open={editing}
          setOpen={setEditing}
        />
      )}
    </>
  );
};

export default EMarkdown;

interface DialogProps {
  id: string;
  value: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const EditDialog: React.FunctionComponent<DialogProps> = ({
  open,
  setOpen,
  id,
  value,
}) => {
  const [text, setText] = React.useState<string>(value);

  const onSubmit = () => {
    save({ [id]: text });
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Markdown"
              multiline
              fullWidth
              variant="outlined"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Markdown>{text}</Markdown>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>
          Abbrechen
        </Button>
        <Button color="primary" onClick={() => onSubmit()}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};
