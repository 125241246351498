import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import { useContext } from "react";
import {
  Hidden,
  IconButton,
  Tabs,
  Tab,
  Toolbar,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  AppBar,
  Typography,
  Fab,
} from "@mui/material";
import styled from "@emotion/styled";
import InstagramIcon from "./assets/InstagramIcon";
import { Logout, Menu } from "@mui/icons-material";
import React from "react";
import { Box } from "@mui/system";
import Werke from "./pages/Werke";
import About from "./pages/About";
import Kontakt from "./pages/Kontakt";
import AuthContext from "./AuthContext";
import { getAuth, signOut } from "firebase/auth";
import Newsletter from "./pages/Newsletter";
import Blog from "./pages/Blog";

const TopBar = styled.div`
  position: static;
  height: 48px;
  min-height: 48px;
  background: white;
  display: flex;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
`;

function App() {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  function instagram() {
    window.open("https://www.instagram.com/dorita.gallery9", "_blank");
  }

  const routes = [
    { label: "Home", path: "/" },
    { label: "Werke", path: "/werke" },
    { label: "Blog", path: "/blog" },
    { label: "About", path: "/about" },
    { label: "Kontakt", path: "/kontakt" },
  ];

  return (
    <div style={{ background: "#fafafa", height: "100%" }}>
      <Newsletter />
      <TopBar>
        <div
          style={{
            height: "100%",
            fontSize: 32,
            fontWeight: 400,
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          <span style={{ fontFamily: "Poppins" }}>dorita</span>
        </div>
        <div style={{ flex: 1 }} />
        <Hidden smDown>
          <Tabs
            onChange={(_, value) => navigate(value)}
            value={location.pathname}
            textColor="primary"
            indicatorColor="primary"
            centered
          >
            {routes.map((r) => (
              <Tab
                disableRipple
                key={r.path}
                sx={{ minWidth: 100 }}
                label={r.label}
                value={r.path}
              />
            ))}
          </Tabs>
        </Hidden>
        <Box m={2} />

        <IconButton color="primary" onClick={() => instagram()}>
          <InstagramIcon />
        </IconButton>
        <Hidden smUp>
          <IconButton
            color="primary"
            ref={anchorRef}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </TopBar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/werke" element={<Werke />} />
        <Route path="/about" element={<About />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>

      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
                <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
                  {routes.map((r) => (
                    <MenuItem
                      key={r.path}
                      onClick={() => {
                        setMenuOpen(false);
                        navigate(r.path);
                      }}
                    >
                      {r.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/* email, title, Logout*/}
      {user && (
        <>
          <Box m={8} />
          <AppBar
            position="fixed"
            color="primary"
            sx={{ top: "auto", bottom: 0 }}
          >
            <Toolbar>
              <Typography>
                <b>{user.email}</b>
              </Typography>
              <Typography variant="h5" sx={{ textAlign: "center", flex: 1 }}>
                Admin Mode
              </Typography>

              <IconButton
                sx={{ color: "white" }}
                onClick={() => signOut(getAuth())}
              >
                <Logout />
              </IconButton>
            </Toolbar>
          </AppBar>
        </>
      )}
    </div>
  );
}

export default App;
